"use strict";

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function init() {// JavaScript to be fired on all pages
      },
      finalize: function finalize() {// JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function init() {
        // JavaScript to be fired on the home page
        $('section.skill .item:first-child .ask').addClass('init-active');
        $('section.skill .item').hover(function () {
          $('section.skill .item:first-child .ask').removeClass('init-active');
        });
        $('section.skill .item').mouseleave(function () {
          $('section.skill .item:first-child .ask').addClass('init-active');
        });
      },
      finalize: function finalize() {// JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function init() {// JavaScript to be fired on the about us page
      }
    }
  }; // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event

  var UTIL = {
    fire: function fire(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function loadEvents() {
      // Fire common init JS
      UTIL.fire('common'); // Fire page-specific init JS, and then finalize JS

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      }); // Fire common finalize JS

      UTIL.fire('common', 'finalize');
    }
  };
  /* Anchor URL */

  if (window.location.hash) {
    var hash = window.location.hash,
        $hash = $(hash);
    $hash.removeAttr('id');
    $hash.before('<div id="' + hash.slice(1) + '" class="hashlink"></div>');
    window.location.hash = hash;
  }
  /* Popover */


  $('[data-toggle="popover"]').popover({
    html: true,
    trigger: 'hover'
  });
  /* Carousel */

  $('.owl-carousel.carousel').owlCarousel({
    items: 2,
    loop: true,
    margin: 15,
    nav: true,
    navText: ["<img src='/wp-content/uploads/2019/04/nav-gauche-team.svg' alt='précédent'>", "<img src='/wp-content/uploads/2019/04/nav-droite-team.svg' alt='suivant'>"],
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 2
      }
    }
  });
  /* Team */

  $('.team-carousel').owlCarousel({
    items: 4,
    loop: true,
    margin: 15,
    nav: true,
    navText: ["<img src='/wp-content/uploads/2019/04/nav-gauche-team.svg' alt='précédent'>", "<img src='/wp-content/uploads/2019/04/nav-droite-team.svg' alt='suivant'>"],
    responsive: {
      0: {
        items: 1
      },
      500: {
        items: 2
      },
      768: {
        items: 4,
        nav: true
      }
    }
  });
  /* Post */

  $('.owl-carousel').owlCarousel({
    items: 1,
    loop: true,
    margin: 10,
    nav: true,
    navText: ["<img src='/wp-content/uploads/2019/04/nav-gauche.svg' alt='précédent' width='21px' height='21px'>", "<img src='/wp-content/uploads/2019/04/nav-droite.svg' alt='suivant' width='21px' height='21px'>"]
  });
  /* Menu Resp */

  $('header .resp .hamburger').click(function () {
    $('header .resp .menu-wrapper').toggleClass('active');
  });
  $('header .resp .menu-contacter-lagence-la-plus-proche').find('ul').toggle();
  $('header .resp li.menu-item-has-children').click(function (e) {
    e.stopPropagation();
    $(this).find('ul').toggle();
  });
  /* Menu / Scrolltop */

  if ($('body').hasClass('home')) {
    $(window).scroll(function () {
      if ($(window).scrollTop() > 100) {
        $('header').addClass('scroll');
        $('.scrolltop').addClass('active');
      } else {
        $('header').removeClass('scroll');
        $('.scrolltop').removeClass('active');
      }
    });
  } else {
    $('header').addClass('scroll');
    $('.scrolltop').addClass('active');
  }

  $('.scrolltop').click(function (e) {
    e.preventDefault();
    $('html, body').animate({
      scrollTop: $('body').offset().top
    }, 1000);
  });
  /* Portfolio */

  $('.portfolio > a').click(function () {
    $('#portfolio-content').fadeTo(300, 0);
    $('#portfolio-content').delay(500).fadeTo(300, 1);
    $('.portfolio a').removeClass('active');
    $(this).addClass('active');
  });
  /* Joboffer */

  $('.joboffer label').click(function () {
    $(this).toggleClass('active');
  });
  $(document).on('click', '.text-filter span', function () {
    var parent = jQuery(this).closest('.item');
    parent.find('.text-filter span.active').removeClass('active');
    parent.find('#text-filter div.active').removeClass('active');
    jQuery(this).addClass('active');
    parent.find('#text-filter div.' + jQuery(this).attr('id') + '-text').addClass('active');
  });
  /* Search */

  $(' .desk .search-img').click(function () {
    $('.desk .search-wrapper').toggle();
  });
  $(' .resp .search-img').click(function () {
    $('.resp + .search-wrapper').toggle();
  });
  /* Icontext Height */

  if ($('.icontext').length) {
    iconTextHeight($('.icontext .title-wrapper + p'));
  }

  function iconTextHeight(e) {
    var maxHeight = -1;
    e.each(function () {
      maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
    });
    e.each(function () {
      $(this).height(maxHeight);
    });
  }

  ;
  if (window.location.hash) $(document).scrollTop($(window.location.hash).offset().top - $('header').outerHeight() - $('#wpadminbar').outerHeight()); // Load Events

  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.